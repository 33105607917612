<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let name;

    const services = ['Société', 'Immobilier', 'Successions', 'Famille', 'Autres'];
    let selectedServices = [];
    let value = "";

    let isSelectorOpen = false;

    function checkSelectedServices() {
        let tmpArray = [];

        for (let i = 0; i < services.length; i++) {
            if(document.querySelector(`#${services[i]}check`).checked) {
                tmpArray.push(services[i]);
            }
        }

        selectedServices = tmpArray;
        value = updateInput();
        
        if(value == "") {
            updateValue(true, false);
        } else {
            updateValue(false, true);
        }

        isSelectorOpen = false;
    }

    function updateInput() {
        let r = "";

        for (let i = 0; i < selectedServices.length; i++) {
            if(i < selectedServices.length) {
                r += selectedServices[i] + ", ";
            } else {
                r += selectedServices[i];
            }
        }

        r = r.replace(/,\s$/, "");

        return r;
    }

    function updateValue(e, v) {
        dispatch('update', {
            id: name,
            empty: e,
			valid: v
		});
    }

</script>

<div class="input-container relative mb-4">
    <input type="hidden" name="{name}" id="{name}" bind:value={value} >
    
    <div class="form-select rounded-lg" on:keypress={isSelectorOpen = !isSelectorOpen} on:click={()=>{isSelectorOpen = !isSelectorOpen}} class:rounded-lg={!isSelectorOpen} class:form-select--open={isSelectorOpen}>
        Type de service <i class="fa-solid fa-chevron-left transition-transform"></i>
    </div>

    <ul class="block w-full absolute left-0 right-0 h-auto z-50 rounded-b-md overflow-hidden shadow-lg" class:pointer-events-none={!isSelectorOpen} class:opacity-0={!isSelectorOpen}>
        {#each services as s, i}
            <li class="block w-full bg-gray-200 hover:bg-gray-300 transition-colors">
                <label for="{s}check" class="form-container text-slate-700">
                    {s}
                    <input type="checkbox" name="{s}check" id="{s}check" class="absolute opacity-0 cursor-pointer h-0 w-0" on:change={checkSelectedServices}>
                    <span class="form-checkmark">
                        <i class="fa-solid fa-check"></i>
                    </span>
                </label>
            </li>
        {/each}
    </ul>
</div>