<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let name;
    export let required;
    export let placeholder;

    let tmpValue = '';
    let err = '';
    let valid = false;

    const bannedMails = ['@yopmail', '@lidte', '@vmani', '@seacob', '@firemailbox', '@tzymail', '@jourrapide', '@armyspy', '@cuvox', '@dayrep', '@einrot', '@fleckens', '@gustr', '@rhyta', '@superrito', '@teleworm'];

    function updateValue(e, v) {
        dispatch('update', {
            id: name,
			empty: e,
            valid: v,
		});
    }

    function verifyValue() {
        if ((tmpValue == '' || tmpValue == null || tmpValue.trim() == '') && required) {
            valid = false;
            tmpValue = null;
            err = 'Champ vide';
            updateValue(true, false);
        } else if((tmpValue == '' || tmpValue == null || tmpValue.trim() == '')) {
            valid = false;
            err = '';
            tmpValue = null;
            updateValue(true, false);
        } else {
			let e = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;
            let test = e.test(tmpValue);

            if (test == true) {
                let isBanned = false;
                bannedMails.forEach(b => {
                    if (tmpValue.includes(b)) isBanned = true;
                });
                if (isBanned == false) {
                    valid = true;
                    err = '';
                    updateValue(false, true);
                } else {
                    valid = false;
                    err = `Adresse mail invalide`;
                    updateValue(false, false);
                }
			} else {
                valid = false;
                err = `Adresse mail invalide`;
                updateValue(false, false);
            }
        }
    }
</script>

<div class="input-container mb-4">
    <input type="text" class="form-control" name="{name}" id="{name}" required={required} placeholder={placeholder} bind:value={tmpValue} on:keyup={verifyValue} on:change={verifyValue} class:error={err != ''} class:valid={valid}>
    <span class="input-status" class:valid={valid} class:err={err != ''}>
        {#if valid}
            <i class="fa-solid fa-check"></i>
        {:else if err != ''}
            <span>{err}</span> <i class="fa-solid fa-xmark"></i>
        {/if}
    </span>
</div>