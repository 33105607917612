<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let name;
    export let required;
    export let placeholder;
    export let autocomplete;
    let tmpValue = '';
    let err = '';
    let valid = false;

    function updateValue(e, v) {
        dispatch('update', {
            id: name,
			empty: e,
            valid: v,
		});
    }

    function verifyValue() {
        if ((tmpValue == '' || tmpValue == null || tmpValue.trim() == '') && required) {
            valid = false;
            tmpValue = null;
            err = 'Champ vide';
            updateValue(true, false);
        } else if((tmpValue == '' || tmpValue == null || tmpValue.trim() == '')) {
            valid = false;
            err = '';
            tmpValue = null;
            updateValue(true, false);
        } else {
			let e = /[^a-zA-Z\s\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ&']+/igm;
            let test = tmpValue.match(e);

            if(test) {
                valid = false;
                err = `Caractères invalides: ${test}`;
                updateValue(false, false);
            } else {
                if(tmpValue.length < 1) {
                    valid = false;
                    err = '1 caractere minimum';
                    updateValue(false, false);
                } else {
                    valid = true;
                    err = '';
                    updateValue(false, valid);
                }
            }
        }
    }
</script>

<div class="input-container mb-4">
    <input type="text" class="form-control" name="{name}" id="{name}" required={required} placeholder={placeholder} bind:value={tmpValue} on:keyup={verifyValue} on:change={verifyValue} class:error={err != ''} class:valid={valid} autocomplete="{autocomplete}">
    <span class="input-status" class:valid={valid} class:err={err != ''}>
        {#if valid}
            <i class="fa-solid fa-check"></i>
        {:else if err != ''}
            <span>{err}</span> <i class="fa-solid fa-xmark"></i>
        {/if}
    </span>
</div>