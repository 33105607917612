<script>
    export let lang;

    function getLangString(id) {
        let c = lang.content.length;
        let r = "err";

        for (let i = 0; i < c; i++) {
            if(lang.content[i].id == id) {
                r = lang.content[i].value;
                break;
            }
        }

        return r;
    }
</script>

<section id="tarifs" class="section">
    <div class="section-container">
        <h1 class="section-heading mb-10">{lang.title}</h1>

        <p class="block mb-8 text-slate-600 text-lg text-justify">{@html getLangString('indexPricingPar1')}</p>

        <p class="block mb-8 text-slate-600 text-lg text-justify">{@html getLangString('indexPricingPar2')}</p>
        
        <p class="block mb-2 text-slate-600 text-lg text-justify">{@html getLangString('indexPricingPar3')}</p>

        <a href="#contactForm" class="button button--sm button--vp rounded-md"><i class="fa-solid fa-comments-dollar"></i> {getLangString('indexPricingButton')}</a>
    </div>
</section>