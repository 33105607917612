<script>
    import TextInput from '../inputs/textInput.svelte';
	import NameInput from '../inputs/nameInput.svelte';
	import EmailInput from '../inputs/emailInput.svelte';
	import PhoneInput from '../inputs/phoneInput.svelte';
	import AddressInput from '../inputs/addressInput.svelte';
	import ParagraphInput from '../inputs/paragraphInput.svelte';
    import SelectInput from '../inputs/selectInput.svelte';
    
	// Form object
	var form = {
		name: 'contact',
		state: "init",
		isValid: false,
		isEmpty: true,
		mailSent: false,
		mailErr: "",
		inputsBlank: {
			"cfPrenom": {
				valid: false,
                empty: true,
				required: true
			},
			"cfNom": {
				valid: false,
                empty: true,
				required: true
			},
			"cfServices": {
				valid: false,
                empty: true,
				required: true
			},
			"cfAdresse": {
				valid: false,
                empty: true,
				required: true
			},
			"cfTel": {
				valid: false,
                empty: true,
				required: true
			},
			"cfEmail": {
				valid: false,
                empty: true,
				required: true
			},
			"cfMessage": {
				valid: false,
                empty: true,
				required: true
			}
		},
		inputs: {
			"cfPrenom": {
				valid: false,
                empty: true,
				required: true
			},
			"cfNom": {
				valid: false,
                empty: true,
				required: true
			},
			"cfServices": {
				valid: false,
                empty: true,
				required: true
			},
			"cfAdresse": {
				valid: false,
                empty: true,
				required: true
			},
			"cfTel": {
				valid: false,
                empty: true,
				required: true
			},
			"cfEmail": {
				valid: false,
                empty: true,
				required: true
			},
			"cfMessage": {
				valid: false,
                empty: true,
				required: true
			}
		}
	};

	var scripts = {
		sendForm: async () => {
			if(form.isValid) {
				let formData = new FormData(document.querySelector('#contactForm'));
				form.state = "sending";

				try {
					var res = await fetch("__prefab/__mailer.php", {
						method: "POST",
						body: formData
					});
					
					var resData = await res.json();

					if(resData.status == "ok") {
						form.mailSent = true;
						scripts.resetForm();
						form.state = "init";
					} else {
						form.state = "error";
						form.mailErr = resData.error;
					}
				} catch (e) {
					form.mailErr = e;
					form.state = "error";
				}
			}
		},
		isInputOk: (i) => {
			let isok = true;

			if(form.inputs[i].empty) {
				if(form.inputs[i].required) {
					isok = false;
					//console.log("input "+i+" is required but empty");
				}
			} else {
				if(!form.inputs[i].valid) {
					isok = false;
					//console.log("input "+i+" is not empty but invalid");
				}
			}

			return isok;
		},
		updateBtn: () => {
			form.state = "init";
			form.isValid = false;

			//console.log("checking inputs..");

			let isfok = true;

			for (const ie in form.inputs) {
				if (Object.hasOwnProperty.call(form.inputs, ie)) {
					if(!scripts.isInputOk(ie)) {
						isfok = false;
						break;
					}
				}
			}

			if(isfok == true) {
				form.state = "valid";
                form.isValid = true;
				//console.log("all inputs are valid");
			}
		},
		resetForm: () => {
			document.querySelector('#contactForm').reset();
			form.inputs = form.inputsBlank;
		},
		updateValue: (event) => {
			try {
				let data = event.detail;
				form.inputs[data.id].valid = data.valid;
				form.inputs[data.id].empty = data.empty;
			} catch (e) {
				console.log(e);
				form.mailErr = e;
				form.state = "error";
			} finally {
				scripts.updateBtn();
			}
		}
	};

	export let lang;

    function getLangString(id) {
        let c = lang.content.length;
        let r = "err";

        for (let i = 0; i < c; i++) {
            if(lang.content[i].id == id) {
                r = lang.content[i].value;
                break;
            }
        }

        return r;
    }
</script>

<section id="contact" class="section">
    <div class="section-container">
        <h1 class="section-heading mb-10">{lang.title}</h1>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">

            <div id="contactInfos" class="w-full flex flex-col justify-start items-start">
                <h3 class="block uppercase text-slate-600 text-lg sm:text-xl w-full">{getLangString('indexContactTitle1')}</h3>
                
                <h2 class="block w-full text-vpgreen-700 text-3xl sm:text-4xl mb-5">{getLangString('indexContactTitle2')}</h2>

                <a href="{getLangString('indexContactAddressLink')}"><address class="block w-full mb-3 not-italic text-slate-700 text-lg sm:text-xl"><i class="fa-solid fa-map-pin text-vp-200 mr-4"></i> {getLangString('indexContactAddress')}</address></a>

                <a class="mb-3 focus:no-underline text-slate-700 text-lg sm:text-xl" href="tel:{getLangString('indexContactPhone')}"><i class="fa-solid fa-phone text-vp-200 mr-4"></i> {getLangString('indexContactPhone')}</a>

                <a class="mb-5 focus:no-underline text-slate-700 text-lg sm:text-xl" href="mailto:{getLangString('indexContactEmail')}"><i class="fa-regular fa-envelope text-vp-200 mr-4"></i> {getLangString('indexContactEmail')}</a>

                <a href="{getLangString('indexContactAddressLink')}" class="block w-full max-w-sm aspect-square rounded-md overflow-hidden">
                    <img src="public/img/{getLangString('indexContactImg')}" alt="Plan d'accès" class="w-full h-full object-center object-contain hover:scale-150 hover:object-left hover:translate-x-24 transition-all">
                </a>
            </div>

            <div id="contactFormContainer" class="block w-full relative overflow-hidden">
				{#if form.mailSent}
					<div class="block w-full rounded-md mb-8 px-8 py-3 text-green-900 bg-green-200 text-left">
						<strong>Génial!</strong> Votre demande a bien été envoyée
					</div>
				{/if}
				{#if form.state == "error"}
					<div class="block w-full rounded-md mb-8 px-8 py-3 text-red-700 bg-red-100 text-left">
						<strong>Une erreur est survenue</strong> {form.mailErr}
					</div>
				{/if}
                <form action="" id="contactForm" class="block w-full">
                    <h2 class="block w-full text-slate-700 mb-4 text-xl font-semibold">Contacter l’étude</h2>

					<input type="hidden" name="efezze9g84ze48g" id="efezze9g84ze48g" style="display:none">

                    <div class="flex flex-col sm:flex-row flex-nowrap justify-start items-stretch sm:items-center gap-x-4">
                        <div class="block sm:inline-block flex-1-50">
                            <NameInput name={'cfPrenom'} placeholder={'Prénom'} autocomplete={'given-name'} required={form.inputs['cfPrenom'].required} on:update={scripts.updateValue} />
                        </div>
                        <div class="block sm:inline-block flex-1-50">
                            <NameInput name={'cfNom'} placeholder={'Nom de famille'} autocomplete={'family-name'} required={form.inputs['cfNom'].required} on:update={scripts.updateValue} />
                        </div>
                    </div>

                    <div class="flex flex-col sm:flex-row flex-nowrap justify-start items-stretch sm:items-center gap-x-4">
                        <EmailInput name={'cfEmail'} placeholder={'Adresse e-mail'} autocomplete="email" required={form.inputs['cfEmail'].required} on:update={scripts.updateValue} />
                    </div>

                    <div class="flex flex-col sm:flex-row flex-nowrap justify-start items-stretch sm:items-center gap-x-4">
                        <AddressInput name={'cfAdresse'} required={form.inputs['cfAdresse'].required} on:update={scripts.updateValue} />
                    </div>

                    <div class="flex flex-col sm:flex-row flex-nowrap justify-start items-stretch sm:items-center gap-x-4">
                        <div class="block sm:inline-block flex-1-50">
                            <SelectInput name={'cfServices'} on:update={scripts.updateValue} />
                        </div>
                        <div class="block sm:inline-block flex-1-50">
                            <PhoneInput name={'cfTel'} placeholder={'Numéro de téléphone'} autocomplete="tel" required={form.inputs['cfTel'].required} on:update={scripts.updateValue} />
                        </div>
                    </div>

                    <div class="flex flex-col sm:flex-row flex-nowrap justify-start items-stretch sm:items-center gap-x-4">
                        <ParagraphInput name={'cfMessage'} placeholder={'Votre message'} required={form.inputs['cfMessage'].required} on:update={scripts.updateValue} />
                    </div>

                    <div class="flex flex-col sm:flex-row flex-nowrap justify-start items-stretch sm:items-center gap-x-4">
                        <button type="button" class="button button--sm rounded-md button--vpgreen" class:disabled={!form.isValid} on:click={scripts.sendForm}><i class="fa-regular fa-paper-plane"></i> Envoyer</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</section>