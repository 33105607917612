<script>
    const date = new Date();
    const year = date.getFullYear();

    export let lang;

    function getLangString(section, id) {
        let c = lang[section].content.length;
        let r = "err";

        for (let i = 0; i < c; i++) {
            if(lang[section].content[i].id == id) {
                r = lang[section].content[i].value;
                break;
            }
        }

        return r;
    }
</script>

<footer id="bottom" class="pt-10 sm:pt-14 pb-24 sm:pb-32 relative scroll-smooth bg-gray-800">
    <span class="footer-bg"></span>
    <div class="section-container">
        <div class="flex flex-col sm:flex-row gap-6 w-full overflow-hidden mb-28 justify-start sm:justify-between items-center sm:items-start">
            <div class="inline-block flex-initial text-center sm:text-left">
                <div class="block w-full h-24 mb-4">
                    <a href="#top" class="flex flex-row justify-start items-center w-auto h-full">
                        <div class="inline-block grow-0 shrink-0 h-full">
                            <img src="public/img/{getLangString(6,'indexFooterLogo')}" alt="{getLangString(0,'indexNavbarSubtitle')}" class="h-full object-contain object-center">
                        </div>
                        <div class="inline-block grow shrink-0 min-w-[120px] text-left">
                            <h1 class="block w-full text-lg font-semibold text-white m-0">{getLangString(0,'indexNavbarTitle1')}</h1>
                            <h2 class="block w-full text-lg text-white m-0">{getLangString(0,'indexNavbarSubtitle')}</h2>
                        </div>
                    </a>
                </div>
                <address class="block w-full text-lg text-gray-300 mb-2 not-italic">{getLangString(5,'indexContactAddress')}</address>
                <a href="tel:{getLangString(5,'indexContactPhone')}" class="block w-full text-lg text-gray-300 mb-2">{getLangString(5,'indexContactPhone')}</a>
                <a href="mailto:{getLangString(5,'indexContactEmail')}" class="block w-full text-lg text-gray-300">{getLangString(5,'indexContactEmail')}</a>
            </div>

            <div class="inline-block flex-initial">
                <ul class="list-none p-0 m-0 text-center sm:text-end">
                    <li class="block w-full mb-3 text-lg font-semibold text-white">Menu</li>
                    <li class="block w-full mb-1">
                        <a href="#top" class="inline-block hover:underline text-lg text-gray-300">{getLangString(6,'indexFooterbackToTop')}</a>
                    </li>
                    <li class="block w-full mb-1">
                        <a href="#activites" class="inline-block hover:underline text-lg text-gray-300">{getLangString(0,'indexNavbarLink1')}</a>
                    </li>
                    <li class="block w-full mb-1">
                        <a href="#portrait" class="inline-block hover:underline text-lg text-gray-300">{getLangString(0,'indexNavbarLink2')}</a>
                    </li>
                    <li class="block w-full mb-1">
                        <a href="#tarifs" class="inline-block hover:underline text-lg text-gray-300">{getLangString(0,'indexNavbarLink3')}</a>
                    </li>
                    <li class="block w-full mb-1">
                        <a href="#contact" class="inline-block hover:underline text-lg text-gray-300">{getLangString(0,'indexNavbarLink4')}</a>
                    </li>
                </ul>
            </div>
        </div>
        <hr class="border-gray-700 mb-10">
        <span class="inline-block text-base text-gray-500 leading-4 select-none">{getLangString(0,'indexNavbarSubtitle')}, Copyright © {year} • Tous droits réservés</span><br>
        <span class="inline-block text-base text-gray-500 leading-4 select-none">Site web propulsé par <a class="text-gray-500 hover:text-gray-100 no-underline" href="https://www.yb-cs.ch/">YBCS</a></span>
    </div>
</footer>