<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let name;
    export let required;

    let err = '';
    let valid = false;
    let value = '';

    var isFocused = false;

    var street = '';
    var number = '';
    var npa = '';
    var city = '';

    function updateValue(e, v) {
        dispatch('update', {
            id: name,
			empty: e,
            valid: v,
		});
    }

    function verifyValue() {
        let tmpValue = street + " " + number + " " + npa + " " + city;

        if ((tmpValue == '' || tmpValue == null || tmpValue.trim() == '') && required) {
            street = null;
            city = null;
            npa = null;
            number = null;

            valid = false;
            err = 'Champ vide';
            updateValue(true, false);
        } else if((tmpValue == '' || tmpValue == null || tmpValue.trim() == '')) {
            street = null;
            city = null;
            npa = null;
            number = null;

            valid = false;
            err = '';
            updateValue(true, false);
        } else {
            let st = street == null ? true : street.match(/[^a-zA-Z\s\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛïöüÏÖÜçÇßœ'.,;:()]+/igm);
            let ct = city == null ? true : city.match(/[^a-zA-Z\s\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛïöüÏÖÜçÇßœ'.,;:()]+/igm);
            let npt = npa == null ? true : npa.match(/[^0-9]+/igm);
            let nt = number == null ? true : number.match(/[^0-9a-zA-Z]+/igm);

            if(st || ct || npt || nt) {
                valid = false;
                err = 'Addresse invalide';
                updateValue(false, false);
            } else {
                valid = true;
                err = '';
                updateValue(false, true);
                value = tmpValue;
            }
        }
    }
</script>

<div class="multi-input-container mb-4" name="address" id="address">
    <div class="multi-input">
        <input type="hidden" name="{name}" id="{name}" bind:value={value}>

        <input type="text" name="{name}street" id="{name}street" autocomplete="street-address" placeholder="Rue" class="a40" bind:value={street} on:focus={() => {isFocused = true;}} on:blur={() => {isFocused = false;}} on:keyup={verifyValue} on:change={verifyValue}>
        <span class="divider"></span>
        <input type="text" name="{name}num" id="{name}num" autocomplete="off" placeholder="N°" class="a10" bind:value={number} on:focus={() => {isFocused = true;}} on:blur={() => {isFocused = false;}} on:keyup={verifyValue} on:change={verifyValue}>
        <span class="divider"></span>
        <input type="text" name="{name}npa" id="{name}npa" autocomplete="postal-code" placeholder="NPA" class="a10" bind:value={npa} on:focus={() => {isFocused = true;}} on:blur={() => {isFocused = false;}} on:keyup={verifyValue} on:change={verifyValue}>
        <span class="divider"></span>
        <input type="text" name="{name}city" id="{name}city" autocomplete="address-level2" placeholder="Ville" class="a40" bind:value={city} on:focus={() => {isFocused = true;}} on:blur={() => {isFocused = false;}} on:keyup={verifyValue} on:change={verifyValue}>
    </div>
    <div class="multi-input-background" class:focused={isFocused}></div>
    <span class="input-status" class:valid={valid} class:err={err != ''}>
        {#if valid}
            <i class="fa-solid fa-check"></i>
        {:else if err != ''}
            <span>{err}</span> <i class="fa-solid fa-xmark"></i>
        {/if}
    </span>
</div>