<script>
    export let lang;

    function getLangString(id) {
        let c = lang.content.length;
        let r = "err";

        for (let i = 0; i < c; i++) {
            if(lang.content[i].id == id) {
                r = lang.content[i].value;
                break;
            }
        }

        return r;
    }
</script>

<section id="portrait" class="section">
    <div class="section-container">
        <h1 class="section-heading mb-10">{lang.title}</h1>
        
        <div class="flex flex-col sm:flex-row justify-start items-center w-full py-6">

            <div class="block sm:inline-block flex-50 w-auto h-auto max-w-md max-h-[448px] aspect-square">
                <img src="public/img/{getLangString('indexPortraitImg')}" alt="Me Violaine Page" class="w-full h-full object-contain object-center rounded-md overflow-hidden">
            </div>

            <div class="flex sm:inline-flex flex-50 flex-col items-center justify-start w-full max-w-md mx-auto py-8">

                <div id="timeline1991" class="flex flex-row gap-4 items-center justify-start w-full ">
                    <span class="inline-block flex-grow-0 flex-shrink-0 text-slate-500 text-xl leading-7 w-11">{getLangString('indexPortraitDate1')}</span>
                    <div class="inline-block flex-grow-0 flex-shrink-0 relative h-[76px] w-11">
                        <span class="absolute block z-0 bg-vp-200 w-[2px] bottom-0 left-middle h-1/2 mx-auto"></span>
                        <span class="absolute block w-3 h-3 z-10 left-[14px] top-[32px] bg-vp-400 box-content border-white border-2 rounded-full"></span>
                    </div>
                    <p class="inline-block flex-grow flex-shrink m-0 p-0 text-slate-700 text-md sm:text-xl leading-5 sm:leading-7 font-semibold">{getLangString('indexPortraitEvent1')}</p>
                </div>

                <div id="timeline2017" class="flex flex-row gap-4 items-center justify-start w-full ">
                    <span class="inline-block flex-grow-0 flex-shrink-0 text-slate-500 text-xl leading-7 w-11">{getLangString('indexPortraitDate2')}</span>
                    <div class="inline-block flex-grow-0 flex-shrink-0 relative h-[76px] w-11">
                        <span class="relative block z-0 bg-vp-200 w-[2px] h-full mx-auto "></span>
                        <span class="absolute block w-3 h-3 z-10 left-[14px] top-[32px] bg-vp-400 box-content border-white border-2 rounded-full"></span>
                    </div>
                    <p class="inline-block flex-grow flex-shrink m-0 p-0 text-slate-700 text-md sm:text-xl leading-5 sm:leading-7 font-semibold">{getLangString('indexPortraitEvent2')}</p>
                </div>

                <div id="timeline2018" class="flex flex-row gap-4 items-center justify-start w-full ">
                    <span class="inline-flex flex-col items-center justify-center flex-grow-0 flex-shrink-0 text-slate-500 text-xl leading-7 w-11">
                        {getLangString('indexPortraitDate3')}
                    </span>
                    <div class="inline-block flex-grow-0 flex-shrink-0 relative h-[76px] w-11">
                        <span class="relative block z-0 bg-vp-200 w-[2px] h-full mx-auto "></span>
                        <span class="absolute block w-3 h-3 z-10 left-[14px] top-[32px] bg-vp-400 box-content border-white border-2 rounded-full"></span>
                    </div>
                    <p class="inline-block flex-grow flex-shrink m-0 p-0 text-slate-700 text-md sm:text-xl leading-5 sm:leading-7 font-semibold">{getLangString('indexPortraitEvent3')}</p>
                </div>

                <div id="timeline2022" class="flex flex-row gap-4 items-center justify-start w-full ">
                    <span class="inline-block flex-grow-0 flex-shrink-0 text-slate-500 text-xl leading-7 w-11">{getLangString('indexPortraitDate4')}</span>
                    <div class="inline-block flex-grow-0 flex-shrink-0 relative h-[76px] w-11">
                        <span class="relative block z-0 bg-vp-200 w-[2px] h-full mx-auto "></span>
                        <span class="absolute block w-3 h-3 z-10 left-[14px] top-[32px] bg-vp-400 box-content border-white border-2 rounded-full"></span>
                    </div>
                    <p class="inline-block flex-grow flex-shrink m-0 p-0 text-slate-700 text-md sm:text-xl leading-5 sm:leading-7 font-semibold">{getLangString('indexPortraitEvent4')}</p>
                </div>

                <div id="timeline2023" class="flex flex-row gap-4 items-center justify-start w-full ">
                    <span class="inline-block flex-grow-0 flex-shrink-0 text-slate-500 text-xl leading-7 w-11">{getLangString('indexPortraitDate5')}</span>
                    <div class="inline-block flex-grow-0 flex-shrink-0 relative h-[76px] w-11">
                        <span class="relative block z-0 bg-vp-200 w-[2px] h-full mx-auto "></span>
                        <span class="absolute block w-3 h-3 z-10 left-[14px] top-[32px] bg-vp-400 box-content border-white border-2 rounded-full"></span>
                    </div>
                    <p class="inline-block flex-grow flex-shrink m-0 p-0 text-slate-700 text-md sm:text-xl leading-5 sm:leading-7 font-semibold">{getLangString('indexPortraitEvent5')}</p>
                </div>

            </div>

        </div>

        
    </div>
</section>