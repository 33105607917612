<script>
    export let lang;

    function getLangString(id) {
        let c = lang.content.length;
        let r = "err";

        for (let i = 0; i < c; i++) {
            if(lang.content[i].id == id) {
                r = lang.content[i].value;
                break;
            }
        }

        return r;
    }
</script>

<header class="flex flex-col justify-end items-stretch w-full box-border min-h-[100vh] bg-transparent pt-[160px]">
    <section id="header" class="bg-white">
        <div class="section-container">
            <h1 class="block w-full text-left sm:text-justify text-[25px] sm:text-7xl relative leading-8 text-vp-600 mb-10">{getLangString('indexHeaderTitle1')} <br class="hidden sm:block"><span class="text-vpgreen-700">{getLangString('indexHeaderName')}</span></h1>
            <p class="block w-full text-justify text-slate-600 text-xl sm:text-[32px]"><i class="fa-solid fa-quote-left mr-2"></i>{getLangString('indexHeaderCita')}<i class="fa-solid fa-quote-right ml-2"></i></p>
        </div>
    </section>

    <section id="banner" class="bg-white p-0 h-72 xxl:h-96">
        <div class="w-full block h-full overflow-hidden">
            <img src="public/img/{getLangString('indexHeaderBanner')}" alt="Illustration de l'étude de notaire de Me Violaine Page" class="w-full h-full object-cover object-center">
        </div>
    </section>
</header>