<script>
	import Navbar from "./components/navbar.svelte";
    import Header from "./components/header.svelte";
    import Activites from "./sections/activites.svelte";
    import Portrait from "./sections/portrait.svelte";
    import Tarifs from "./sections/tarifs.svelte";
    import Contact from "./sections/contact.svelte";
    import Footer from "./components/footer.svelte";

    import { onMount } from "svelte";

    var fipList = null;
    var firList = null;
    var filList = null;
    const wtd = 900;

    var isNavScroll = false;

    const promise = fetch('api/get/lang.php').then(response => response.json());

    onMount(async () => {
		fipList = document.querySelectorAll(".fip");
        firList = document.querySelectorAll(".fir");
        filList = document.querySelectorAll(".fil");
	});

    window.addEventListener('scroll', () => {
        if(fipList == null) {
            fipList = document.querySelectorAll(".fip");
            firList = document.querySelectorAll(".fir");
            filList = document.querySelectorAll(".fil");
        }

        var winTop = document.documentElement.scrollTop || document.body.scrollTop;

        fipList.forEach((e) => {
            let topOffset = e.getBoundingClientRect().top;
            let pos = topOffset + winTop;

            if (pos < winTop + wtd) {
                e.classList.add("animate__fadeInUp");
            }
        });

        filList.forEach((e) => {
            let topOffset = e.getBoundingClientRect().top;
            let pos = topOffset + winTop;

            if (pos < winTop + wtd) {
                e.classList.add("animate__fadeInLeft");
            }
        });

        firList.forEach((e) => {
            let topOffset = e.getBoundingClientRect().top;
            let pos = topOffset + winTop;

            if (pos < winTop + wtd) {
                e.classList.add("animate__fadeInRight");
            }
        });

        if (winTop > 200) {
            document.querySelector('nav').classList.add('nav-scroll');
            isNavScroll = true;

        } else {
            document.querySelector('nav').classList.remove('nav-scroll');
            isNavScroll = false;
        }
    });
</script>

{#await promise}
    <main class="flex flex-col items-center justify-center w-full h-screen fixed top-0 left-0 right-0 bottom-0 bg-white">
        <div class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Chargement...</span>
        </div>
    </main>
{:then langData}
    <Navbar lang={langData[0].values[0]} isNavScroll={isNavScroll} />
    <Header lang={langData[0].values[1]} />
    <Activites lang={langData[0].values[2]} />
    <Portrait lang={langData[0].values[3]} />
    <Tarifs lang={langData[0].values[4]} />
    <Contact lang={langData[0].values[5]} />
    <Footer lang={langData[0].values} />
{:catch error}
    <p>Something went wrong: {error.message}</p>
{/await}