<script>
    export let lang;

    function getLangString(id) {
        let c = lang.content.length;
        let r = "err";

        for (let i = 0; i < c; i++) {
            if(lang.content[i].id == id) {
                r = lang.content[i].value;
                break;
            }
        }

        return r;
    }
</script>

<section id="activites" class="section">
    <div class="section-container">
        <h1 class="section-heading mb-10">{lang.title}</h1>
        <p class="block mb-8 text-slate-600 text-lg text-justify">{@html getLangString('indexActivityPar1')}</p>
        <div class="grid grid-cols-1 sm:grid-cols-2 w-full mt-8 gap-12 py-8">
            <div class="flex flex-col items-start justify-center w-full rounded-lg">
                <div class="w-16 h-16 block overflow-hidden fill-vp-200">
                    <img src="public/img/magasin.svg" alt="Icone {getLangString('indexActivityTitle2')}">
                </div>
                <h3 class="block w-full text-left text-vp-600 text-3xl uppercase my-4">{getLangString('indexActivityTitle2')}</h3>
                <p class="block w-full list-none m-0 mb-2 p-0 text-justify text-base text-slate-600">
                    {@html getLangString('indexActivityPar2')}
                </p>
            </div>
            <div class="flex flex-col items-start justify-center w-full rounded-lg">
                <div class="w-16 h-16 block overflow-hidden fill-vp-200">
                    <img src="public/img/immobilier.svg" alt="Icone {getLangString('indexActivityTitle3')}">
                </div>
                <h3 class="block w-full text-left text-vp-600 text-3xl uppercase my-4">{getLangString('indexActivityTitle3')}</h3>
                <p class="block w-full list-none m-0 mb-2 p-0 text-justify text-base text-slate-600">
                    {@html getLangString('indexActivityPar3')}
                </p>
            </div>
            <div class="flex flex-col items-start justify-center w-full rounded-lg">
                <div class="w-16 h-16 block overflow-hidden fill-vp-200">
                    <img src="public/img/successions.svg" alt="Icone {getLangString('indexActivityTitle4')}">
                </div>
                <h3 class="block w-full text-left text-vp-600 text-3xl uppercase my-4">{getLangString('indexActivityTitle4')}</h3>
                <p class="block w-full list-none m-0 mb-2 p-0 text-justify text-base text-slate-600">
                    {@html getLangString('indexActivityPar4')}
                </p>
            </div>
            <div class="flex flex-col items-start justify-center w-full rounded-lg">
                <div class="w-16 h-16 block overflow-hidden fill-vp-200">
                    <img src="public/img/famille.svg" alt="Icone {getLangString('indexActivityTitle5')}">
                </div>
                <h3 class="block w-full text-left text-vp-600 text-3xl uppercase my-4">{getLangString('indexActivityTitle5')}</h3>
                <p class="block w-full list-none m-0 mb-2 p-0 text-justify text-base text-slate-600">
                    {@html getLangString('indexActivityPar5')}
                </p>
            </div>
            <div class="flex flex-col items-start justify-center w-full rounded-lg">
                <div class="w-16 h-16 block overflow-hidden fill-vp-200">
                    <img src="public/img/divers.svg" alt="Icone {getLangString('indexActivityTitle6')}">
                </div>
                <h3 class="block w-full text-left text-vp-600 text-3xl uppercase my-4">{getLangString('indexActivityTitle6')}</h3>
                <p class="block w-full list-none m-0 mb-2 p-0 text-justify text-base text-slate-600">
                    {@html getLangString('indexActivityPar6')}
                </p>
            </div>
        </div>
    </div>
</section>