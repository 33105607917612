<script>
    export let isNavScroll;
    let isMobileMenuOpen = false;

    function openMobileMenu() {
        isMobileMenuOpen = true;
    }

    function closeMobileMenu() {
        isMobileMenuOpen = false;
    }

    export let lang;

    function getLangString(id) {
        let c = lang.content.length;
        let r = "err";

        for (let i = 0; i < c; i++) {
            if(lang.content[i].id == id) {
                r = lang.content[i].value;
                break;
            }
        }

        return r;
    }
</script>

<nav>
    <div class="nav-container">
        <div class="inline-flex flex-col justify-center items-start h-full max-w-[203px] sm:max-w-[300px]">
            <a href="#top" class="nav-brand">
                <div class="inline-block flex-grow-0 flex-shrink-0 h-full">
                    {#if isNavScroll}
                        <img id="bold" src="public/img/{getLangString('indexNavbarSmallIcon')}" alt="{getLangString('indexNavbarSubtitle')}" class="animate__animated animate__faster animate animate__fadeInLeft h-full object-contain object-center">
                    {:else}
                        <img id="thin" src="public/img/{getLangString('indexNavbarLargeIcon')}" alt="{getLangString('indexNavbarSubtitle')}" class="animate__animated animate__faster animate animate__fadeInLeft h-full object-contain object-center">
                    {/if}
                </div>
                <div class="inline-block flex-grow flex-shrink-0 text-left min-w-[120px] max-w-[144px]">
                    <h1 class="block w-full text-lg font-semibold text-vp-500 m-0">{getLangString('indexNavbarTitle1')}</h1>
                    <h2 class="block w-full text-lg text-vp-500 m-0">{getLangString('indexNavbarSubtitle')}</h2>
                </div>
            </a>
        </div>
        <ul class="hidden sm:inline-flex flex-row justify-end items-center h-auto list-none pl-3 m-0">
            <li class="inline-block">
                <a href="#activites" class="nav-link">{getLangString('indexNavbarLink1')}</a>
            </li>
            <li class="inline-block">
                <a href="#portrait" class="nav-link">{getLangString('indexNavbarLink2')}</a>
            </li>
            <li class="inline-block">
                <a href="#tarifs" class="nav-link">{getLangString('indexNavbarLink3')}</a>
            </li>
            <li class="inline-block">
                <a href="#contact" class="nav-link">{getLangString('indexNavbarLink4')}</a>
            </li>
        </ul>
        <div class="inline-block h-auto aspect-square w-14 relative sm:hidden">
            <button type="button" title="Navigation" class="w-full h-full text-center flex flex-row items-center justify-center rounded-md text-gray-700 text-3xl" on:click={openMobileMenu}>
                <i class="fa-solid fa-bars"></i>
            </button>
        </div>
    </div>
</nav>

<span id="backdrop" class="block fixed w-full h-[100vh] top-0 left-0 right-0 bottom-0 bg-slate-600 opacity-0 z-[51] transition-opacity" class:opacity-50={isMobileMenuOpen} class:pointer-events-none={!isMobileMenuOpen} on:click={closeMobileMenu} on:keypress={closeMobileMenu}></span>

<div class="{isMobileMenuOpen ? "" : "translate-x-[-100%]"} flex flex-col gap-6 justify-start items-stretch fixed z-[52] top-0 left-0 h-[100vh] w-4/5 overflow-y-hidden bg-white shadow-lg transition-transform" class:pointer-events-none={!isMobileMenuOpen}>
    <div class="block h-28 w-full">
        <a href="#top" class="bg-slate-100 flex flex-row justify-start items-center w-auto h-full p-8" on:click={closeMobileMenu} on:keypress={closeMobileMenu}>
            <div class="inline-block flex-initial h-full w-auto">
                <img id="bold" src="public/img/{getLangString('indexNavbarSmallIcon')}" alt="Me Violaine Page" class="block w-full h-full object-contain object-center">
            </div>
            <div class="inline-block text-left">
                <h1 class="block w-full text-lg font-semibold text-vp-500 m-0">{getLangString('indexNavbarTitle1')}</h1>
                <h2 class="block w-full text-lg text-vp-500 m-0">{getLangString('indexNavbarSubtitle')}</h2>
            </div>
        </a>
    </div>
    <ul class="h-auto list-none py-4 m-0">
        <li class="block">
            <a href="#activites" class="block w-full h-16 text-vp-400 text-2xl py-[22px] px-10 font-semibold no-underline" on:click={closeMobileMenu} on:keypress={closeMobileMenu}>{getLangString('indexNavbarLink1')}</a>
        </li>
        <li class="block">
            <a href="#portrait" class="block w-full h-16 text-vp-400 text-2xl py-[22px] px-10 font-semibold no-underline" on:click={closeMobileMenu} on:keypress={closeMobileMenu}>{getLangString('indexNavbarLink2')}</a>
        </li>
        <li class="block">
            <a href="#tarifs" class="block w-full h-16 text-vp-400 text-2xl py-[22px] px-10 font-semibold no-underline" on:click={closeMobileMenu} on:keypress={closeMobileMenu}>{getLangString('indexNavbarLink3')}</a>
        </li>
        <li class="block">
            <a href="#contact" class="block w-full h-16 text-vp-400 text-2xl py-[22px] px-10 font-semibold no-underline" on:click={closeMobileMenu} on:keypress={closeMobileMenu}>{getLangString('indexNavbarLink4')}</a>
        </li>
    </ul>
</div>
